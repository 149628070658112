import React from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
    //Learning scenario states
    analogId: "column inactive",    //todo: reset to active, once first scenarios are live
    digitalId: "column active",
    onSiteId: "column inactive",
    securityConceptsId: "column inactive", 

    //publications states
    pressId: "column active",
    activitiesId: "column inactive",
    eventsId: "column inactive",
    flyerId: "column inactive",
    networkingId: "column inactive",
    publicationsId: "column inactive",
    //header
    navMenuActive: "inactive",
}

function reducer(state, action){
    switch(action.type){
        case "CHANGE_LS_ANALOG": {
            return {
                ...state,
                analogId: "column active",
                digitalId: "column inactive",
                onSiteId: "column inactive",
                securityConceptsId: "column inactive",
            }
        }
        case "CHANGE_LS_DIGITAL": {
            return {
                ...state,
                analogId: "column inactive",
                digitalId: "column active",
                onSiteId: "column inactive",
                securityConceptsId: "column inactive",
            }
        }
        case "CHANGE_LS_ONSITE": {
            return {
                ...state,
                analogId: "column inactive",
                digitalId: "column inactive",
                onSiteId: "column active",
                securityConceptsId: "column inactive",
            }
        }
        case "CHANGE_LS_SECURITY": {
            return {
                ...state,
                analogId: "column inactive",
                digitalId: "column inactive",
                onSiteId: "column inactive",
                securityConceptsId: "column active",
            }
        }

        case "CHANGE_PUB_PRESS": {
            return {
                ...state,
                pressId: "column active",
                activitiesId: "column inactive",
                eventsId: "column inactive",
                flyerId: "column inactive",
                networkingId: "column inactive",
                publicationsId: "column inactive",
            }
        }
        case "CHANGE_PUB_ACTIVITIES": {
            return {
                ...state,
                pressId: "column inactive",
                activitiesId: "column active",
                eventsId: "column inactive",
                flyerId: "column inactive",
                networkingId: "column inactive",
                publicationsId: "column inactive",
            }
        }
        case "CHANGE_PUB_EVENTS": {
            return {
                ...state,
                pressId: "column inactive",
                activitiesId: "column inactive",
                eventsId: "column active",
                flyerId: "column inactive",
                networkingId: "column inactive",
                publicationsId: "column inactive",
            }
        }
        case "CHANGE_PUB_FLYER": {
            return {
                ...state,
                pressId: "column inactive",
                activitiesId: "column inactive",
                eventsId: "column inactive",
                flyerId: "column active",
                networkingId: "column inactive",
                publicationsId: "column inactive",
            }
        }
        case "CHANGE_PUB_NETWORKING": {
            return {
                ...state,
                pressId: "column inactive",
                activitiesId: "column inactive",
                eventsId: "column inactive",
                flyerId: "column inactive",
                networkingId: "column active",
                publicationsId: "column inactive",
            }
        }
        case "CHANGE_PUB_PUBLICATIONS": {
            return {
                ...state,
                pressId: "column inactive",
                activitiesId: "column inactive",
                eventsId: "column inactive",
                flyerId: "column inactive",
                networkingId: "column inactive",
                publicationsId: "column active",
            }
        }

        case "TOGGLE_NAVMENU": {
            return {
                ...state,
                navMenuActive: state.navMenuActive === "active" ? "inactive" : "active",
            }
        }
        case "DEACTIVATE_NAVMENU": {
            return {
                ...state,
                navMenuActive: "inactive",
            }
        }

        default:
            throw new Error("Bad Action Type")
    }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
